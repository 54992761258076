<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <div class="flix-container">
          <flixCheckPermissions role="customer" permission="1" error>
            <bookedEvents />
          </flixCheckPermissions>
        </div>
      </template>
    </flixDashboard>
  </div>
</template>
<script>
export default {
  name: 'eventBookingDashboard',
  components: {
    bookedEvents () { return import('@/components/booking/events') }
  },
  props: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="sass" scoped></style>
